import { Box, Container, VStack } from '@chakra-ui/react';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import GradientText from '../components/GradientText/GradientText';
import { Page } from '../components/Page';
import Paragraph from '../components/Paragraph/Paragraph';
import PortfolioPagePreview from '../components/PortfolioPagePreview/PortfolioPagePreview';
import projects from '../data/projects';

export default function Portfolio() {
  const intl = useIntl();

  return (
    <Page>
      <Container pt={[2, 8]} pb={[16, 24]} maxWidth={['100%', '80%']}>
        <GradientText textAlign="center">{intl.formatMessage({ id: 'pages.portfolio.title' })}</GradientText>
        <Box>
          <Paragraph>{intl.formatMessage({ id: 'pages.portfolio.description' })}</Paragraph>
        </Box>
        <VStack spacing={24} pt={[2, 4]} align="stretch">
          {projects.map((project) => (
            <Box
              key={project.id}
              h="600px"
              borderWidth="2px"
              borderRadius="30px"
              borderColor="gray.200"
              boxShadow="10px 10px 30px #888"
              overflow="hidden"
            >
              <PortfolioPagePreview src={project.url} />
            </Box>
          ))}
        </VStack>
      </Container>
    </Page>
  );
}
