import React from 'react';

interface PortfolioPagePreviewProps {
  src: string;
  width?: string;
  height?: string;
}

const PortfolioPagePreview: React.FC<PortfolioPagePreviewProps> = ({ src, width = '100%', height = '100%' }) => {
  return (
    <iframe
      src={src}
      style={{
        width,
        height,
        border: 'none',
      }}
      title="Live Preview"
      sandbox="allow-same-origin allow-scripts"
    />
  );
};

export default PortfolioPagePreview;
