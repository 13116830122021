interface Project {
  id: string;
  name: string;
  url: string;
  imagePath: string;
}

const tempImage =
  'https://plus.unsplash.com/premium_photo-1674939148088-d71acc1541ff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80';

const projects: Project[] = [
  {
    id: '1',
    name: 'inspire',
    url: 'https://demo.wpzoom.com/inspiro-lite/',
    imagePath: tempImage,
  },
  {
    id: '2',
    name: 'clothing-store',
    url: 'https://preview.colorlib.com/theme/essence/',
    imagePath: tempImage,
  },
  {
    id: '3',
    name: 'electronics-store',
    url: 'https://wpthemes.themehunk.com/topstore/',
    imagePath: tempImage,
  },
  {
    id: '4',
    name: 'seo-hosting',
    url: 'https://preview.colorlib.com/theme/seos/',
    imagePath: tempImage,
  },
];

export default projects;
